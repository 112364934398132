import * as React from 'react'
import Link from 'gatsby-link'
import { DefaultLayout } from '../layouts/main-layout'
import { BlurbWithImage } from '../components/blurb-with-image'
import { WHAT_WE_DO } from './what-we-do'

export default function IndexPage() {
  return (
    <DefaultLayout name="Home" description="BridgePhase, LLC: Where Innovation and Performance Converge.">
      <section className="hero">
        <div>
          <h1>
            Where <em>Innovation</em> and <em>Performance</em> <em className="alt">Converge</em>
          </h1>
          <p className="suppress-first-para-styling">
            BridgePhase specializes in designing, building, and implementing digital services that help advance the
            mission of our Federal Government while preparing for new challenges ahead.
          </p>
        </div>
      </section>
      <section>
        <h2>What We Do</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {WHAT_WE_DO.map((capability) => (
            <BlurbWithImage
              title={capability.title}
              key={capability.title}
              description={capability.gloss}
              image={capability.image}
              readMoreLink={`/what-we-do/#${capability.anchor}`}
            />
          ))}
        </div>
      </section>

      <section className="responsive-container pt-0">
        <h2>Our Culture</h2>
        <div
          className="img-content"
          style={{ backgroundImage: `url(/images/stock/latest-technology.jpg)` }}
          title="Agile team meeting"
        />
        <div className="main-content">
          <p>
            Our mission is to empower our clients and employees to realize their potential, achieve amazing results, and
            advance the mission of our Federal government. We do this by providing an environment that fosters the
            growth, innovation, collaboration, and delivery excellence needed to achieve successful and lasting
            transformative IT modernization.
          </p>
          <p>
            Our team thrives in working in an Agile environment and is relentlessly committed to delivery,
            accountability, and integrity. Learn more about joining our team today!
          </p>
          <Link to="/careers" className="link-button">
            Careers at BridgePhase
          </Link>
        </div>
      </section>
    </DefaultLayout>
  )
}
