import * as React from 'react'
import Link from 'gatsby-link'

export function BlurbWithImage({
  description,
  image,
  readMoreLink,
  title,
}: {
  description: string
  image: any
  readMoreLink?: string
  title: string
}) {
  return (
    <div className="blurb-with-image">
      <div
        className="blurb-image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="blurb-content">
        <h3 className="blurb-title"><a className="suppress-visited" href={readMoreLink}>{title}</a></h3>

        <p className="suppress-first-para-styling">{description}</p>

        {readMoreLink && (
          <Link to={readMoreLink} className="suppress-visited">
            Read More...
          </Link>
        )}
      </div>
    </div>
  )
}
